import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

firebase.initializeApp({
  apiKey: "AIzaSyChKTdWyMPaDus0Ezo0oS9Ift2u5MdrFnM",
  authDomain: "slared.firebaseapp.com",
  projectId: "slared",
  storageBucket: "slared.appspot.com",
  messagingSenderId: "849996692636",
  appId: "1:849996692636:web:22b6cfcd276d0c83038159",
  measurementId: "G-2N70TN5RCZ",
});

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch((err) => {
    console.log(err);
  });

export { firebase };
