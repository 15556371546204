<template>
  <v-app id="inspire">
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import { firebase } from "@/firebase";

export default {
  created: function() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            if (idTokenResult.claims.allow_access) {
              console.log("llamada a Bind");
              let admin = false;
              if (idTokenResult.claims.allow_access_admin) admin = true;
              this.$store.commit("setUser", {
                uid: user.uid,
                name: user.email,
                admin: admin,
              });
              this.$store.dispatch("bindAdet");
            } else this.clearUser();
          })
          .catch(() => {
            if (this.$store.state.user) {
              this.$store.dispatch("bindAdet");
            }
          });
      } else this.clearUser();
    });
  },
  methods: {
    clearUser() {
      this.$store.commit("setUser", null);
      if (
        !["login", "logout", "nofound", "noaccess", "nopersistence"].includes(
          this.$route.name
        )
      )
        this.$router.push({ name: "login" });
    },
  },
};
</script>
