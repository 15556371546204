import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { firebase } from "@/firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      uid: null,
      name: null,
      admin: null,
    },
    medicion_adet: [],
  },
  plugins: [createPersistedState()],
  mutations: {
    setUser: (state, payload) => {
      state.user = payload;
    },
    setAdet: (state, payload) => {
      state.medicion_adet = payload;
    },
  },
  actions: {
    async bindAdet({ commit }) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`adet.json`);
      const file_url = await fileRef.getDownloadURL();
      const obj = await (await fetch(file_url)).json();
      commit("setAdet", obj);
    },
  },
  modules: {},
});
